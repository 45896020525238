import { useOpenModal } from '../modals/use-open-modal'
import type { CtaButtons_ListingFragment } from './__generated__/cta-buttons.gql'
import { type ModalConfiguration, ModalName } from '../modals/modals.config'
import { lifestyleRefinementMap } from '@brand/search/refinements'

type UseOpenModalClarificationBaseProps = {
  listing: CtaButtons_ListingFragment
}

type UseOpenModalClarificationProps =
  | (UseOpenModalClarificationBaseProps & {
      nextModalProps: ModalConfiguration
      openNextDialogModal?: never
    })
  | (UseOpenModalClarificationBaseProps & {
      nextModalProps?: never
      openNextDialogModal: () => void
    })

export const useOpenModalClarification = ({
  listing,
  nextModalProps,
  openNextDialogModal,
}: UseOpenModalClarificationProps) => {
  const openModal = useOpenModal()
  let impressionEventSelectionValue = ''

  if (nextModalProps) {
    switch (nextModalProps?.id) {
      case ModalName.LEAD_FORM:
        impressionEventSelectionValue = 'email_lead'
        break
      case ModalName.TOUR_WIZARD:
      case ModalName.REQUEST_A_TOUR_WIZARD:
        impressionEventSelectionValue = 'tour_lead'
        break
      default:
        impressionEventSelectionValue = ''
    }
  }

  const shouldShowClarificationModal = ({
    amenitiesHighlighted,
    categoryBadges,
    incomeRestrictions,
    specialTerms,
  }: CtaButtons_ListingFragment) => {
    const containsIncomeRestrictedAmenity =
      amenitiesHighlighted?.includes(
        lifestyleRefinementMap['income-restricted'].label
      ) ||
      categoryBadges?.includes(
        lifestyleRefinementMap['income-restricted'].label
      )

    const hasClarifications =
      Boolean(incomeRestrictions?.length) || Boolean(specialTerms?.length)

    return containsIncomeRestrictedAmenity && hasClarifications
  }

  if (openNextDialogModal && typeof openNextDialogModal === 'function') {
    return () =>
      shouldShowClarificationModal(listing)
        ? openModal({
            id: ModalName.INCOME_RESTRICTIONS_CLARIFICATION,
            props: {
              listing,
              openNextDialogModal: () => openNextDialogModal(),
              impressionEventSelectionValue, // used as selection(CD67) for tagging
            },
          })
        : openNextDialogModal()
  }

  return () =>
    shouldShowClarificationModal(listing)
      ? openModal({
          id: ModalName.INCOME_RESTRICTIONS_CLARIFICATION,
          props: {
            listing,
            openNextModal: () => openModal(nextModalProps),
            impressionEventSelectionValue, // used as selection(CD67) for tagging
          },
        })
      : openModal(nextModalProps)
}
