export function getAmenityAlias(amenity: string) {
  return amenityMap[amenity] || amenity
}

export const amenityMap: Record<string, string> = {
  'Accepts Electronic Payments': 'Electronic Payment Options',
  'Air Conditioning': 'AC',
  'Basketball Court(s)': 'Basketball Court',
  'Business Center': 'Office Center/Conference Room',
  'Cable Ready': 'TV Cable Ready',
  'Campus Shuttle': 'Campus Shuttle Service',
  'Ceiling Fan(s)': 'Ceiling Fans',
  'Certified Efficient Windows': 'Energy-Efficient Windows',
  'Climate Controlled': 'Climate-Controlled',
  Clubhouse: 'Resident Lounge',
  'Community Compost': 'On-Site Compost',
  'Community Garden': 'Shared Garden Space',
  'Controlled Access': 'Controlled Building Access',
  'Corporate Billing Available': 'Corporate Housing Available',
  'Door Attendant': 'Doorman',
  'EV Charging Stations': 'EV Chargers',
  'Energy Star certified Appliances': 'Energy-Efficient Appliances',
  'Extra Storage': 'Additional Storage Space',
  'Fitness Center': 'Gym',
  'Full Concierge Service': 'Full-Service Concierge',
  'Gas Range': 'Gas Stove',
  'Green Community': 'Eco-Friendly Community',
  'High Speed Internet Access': 'High-Speed Internet Available',
  'Internet Included': 'Complementary Internet',
  'Island Kitchens': 'Kitchen Island',
  'Laundry Facility': 'On-Site Laundry',
  'Loft Layout': 'Loft-style',
  'Media Center': 'Media Room',
  'New/Renovated Interior': 'Newly Updated Interior',
  'Non-Smoking': 'Smoke-Free',
  'On Site Patrol': 'Security Guards On Site',
  'Oversized Closets': 'Large Closets',
  'Public Transportation': 'Public Transit Nearby',
  'Racquetball Court(s)': 'Racquetball Court',
  'Recreation Room': 'Rec Room',
  'Short Term Available': 'Short Term Leases Available',
  'Smart Thermostat': 'Smart Thermostats',
  'Smoke Free': 'Smoke-Free',
  'Some Paid Utilities': 'Partially Paid Utility Costs',
  'Sublets Allowed': 'Subletting Permitted',
  'Swimming Pool': 'Pool',
  'Tennis Court(s)': 'Tennis Court',
  'University Shuttle Service': 'University Shuttle',
  'Vaulted Ceilings': 'High Ceilings',
  View: 'Scenic Views',
  'Washer & Dryer Connections': 'Washer/Dryer Connections',
  'Washer & Dryer In Unit': 'Washer/Dryer In Unit',
  'Wireless Internet Access': 'Wi-Fi',
}

export const highlightOrder = [
  'Wireless Internet Access',
  'Washer & Dryer In Unit',
  'Internet Included',
  'Fitness Center',
  'Swimming Pool',
  'Hardwood Flooring',
  'Gated Access',
  'Oversized Closets',
  'New/Renovated Interior',
  'Elevator',
  'Tennis Court(s)',
  'Pet Park',
  'Trail, Bike, Hike, Jog',
  'Stainless Steel Appliances',
  'EV Charging Stations',
  'On Site Maintenance',
  'Emergency Maintenance',
  'Laundry Facility',
  'Washer & Dryer Connections',
  'Extra Storage',
  'Balcony, Patio, Deck',
  'Fireplace',
  'Air Conditioning',
  'Playground',
]

export const isListWithIcons = true
