'use client'
import { yieldOrContinue } from 'main-thread-scheduling'
import clsx from 'clsx'
import type { HTMLAttributes } from 'react'
import {
  useImperativeHandle,
  Children,
  forwardRef,
  useCallback,
  useId,
  useRef,
  useState,
  useEffect,
} from 'react'
import { ReactComponent as ChevronLeftIcon } from '@brand/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from '@brand/icons/chevron-right.svg'
import baseStyles from './scroll-snap-carousel.module.css'
import { DIRECTION } from './scroll-snap-carousel.const'
import type { CarouselButtonRef } from './scroll-snap-carousel-button'
import { CarouselButton } from './scroll-snap-carousel-button'

export interface ScrollSnapProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  disableScrollBars?: boolean

  index?: number
  /**
   * whether or not to render the full carousel or
   * just 1 slide up front
   *
   * NOTE: please be aware that this will only render
   * the first index slide or the slide passed by the index
   * prop if true up front. if you have a multi-slide carousel,
   * you should set this to false
   *
   * @default true
   */
  lazy?: boolean
  name?: string
  /**
   * currentIndex call made after the slide has changed
   * and the current index is in view
   */
  onSlideChange?: (currentIndex: number, direction: DIRECTION | null) => void
  /**
   * whether or not to display the carousel controls
   *
   * @default true
   */
  shouldDisplayControls?: boolean
  styles?: {
    track?: string
    nextBtn?: string
    prevBtn?: string
  }
}

export const ScrollSnapCarousel = forwardRef<HTMLDivElement, ScrollSnapProps>(
  (props: ScrollSnapProps, forwardedRef) => {
    const {
      children,
      onSlideChange,
      className,
      disableScrollBars,
      styles,
      name,
      index,
      shouldDisplayControls = true,
      lazy = true,
    } = props

    const ref = useRef<HTMLDivElement>(null)
    const id = useId()
    const [hasIntersected, setHasIntersected] = useState(!lazy)
    const trackRef = useRef<HTMLDivElement>(null)
    const slideIndexRef = useRef<number[] | null>(null)
    const childrenArr = Children.toArray(children)
    const totalSlides = Children.count(children)
    const slideIndex = Math.min(Math.max(index ?? 0, 0), totalSlides - 1)
    const carouselId = `${id}${name ? `-${name}` : ''}-carousel`
    const nextButtonRef = useRef<CarouselButtonRef>(null)
    const prevButtonRef = useRef<CarouselButtonRef>(null)
    const firstChildWidthRef = useRef<number | null>(null)
    const intersectionRef = useRef<IntersectionObserver | null>(null)
    const resizeRef = useRef<ResizeObserver | null>(null)
    const directionRef = useRef<DIRECTION | null>(null)

    useImperativeHandle(forwardedRef, () => ref.current!)

    useEffect(() => {
      if (
        !trackRef.current ||
        !prevButtonRef.current ||
        !nextButtonRef.current ||
        !shouldDisplayControls ||
        childrenArr.length === 0
      ) {
        return
      }

      const slides = Array.from(trackRef.current.children)
      const firstSlide = slides[0]
      const lastSlide = slides[slides.length - 1]

      if (!intersectionRef.current) {
        intersectionRef.current = new IntersectionObserver(
          async (entries) => {
            await yieldOrContinue('interactive')
            // this is to keep track of the current slides intersecting
            const current = [] as number[]
            entries.forEach((entry) => {
              if (entry.target === firstSlide) {
                entry.isIntersecting
                  ? prevButtonRef.current?.hide()
                  : prevButtonRef.current?.show()
              } else if (entry.target === lastSlide) {
                entry.isIntersecting
                  ? nextButtonRef.current?.hide()
                  : nextButtonRef.current?.show()
              }

              if (entry.isIntersecting) {
                current.push(slides.indexOf(entry.target))
              }
            })

            /**
             * this is mostly because of HMR. don't do anything
             * if there are no intersecting indices because it
             * means there has been no slide change
             *
             * NOTE: this *will* pass this check if the viewport
             * is resized which is probably ok
             */
            if (current.length > 0) {
              /**
               * check the exiting index ref against the just
               * pushed intersecting indices to determine if
               * the slide has changed
               * if it has it wasn't and the current
               * `slideIndexRef` has been set already,
               * call the onSlideChange callback
               */
              if (
                slideIndexRef.current !== null &&
                slideIndexRef.current[0] !== current[0]
              ) {
                /**
                 * if a navigation click has been made,
                 * pass the direction to the callback
                 * and let the callback determine what
                 * to do with it
                 */
                await yieldOrContinue('interactive')
                onSlideChange?.(current[0], directionRef.current)

                directionRef.current = null
              }
              // update the index ref to the current intersecting indices
              slideIndexRef.current = current
              directionRef.current = null
            }
          },
          { threshold: 1, root: trackRef.current, rootMargin: '10% 0% 10% 0%' }
        )
      }

      // yes, observe *all* the slides so we can determine
      // the current index
      slides.forEach((child) => {
        intersectionRef.current?.observe(child)
      })

      return () => {
        intersectionRef.current?.disconnect()
      }
    }, [name, onSlideChange, shouldDisplayControls, childrenArr])

    /**
     * set the first child width to be used for scrolling
     * only set it once because it shouldn't change and we don't
     * want to have to call `offsetWidth` on every scroll
     * a ResizeObserver in the event the slide has been resized
     * so we can recalculate the width
     */
    useEffect(() => {
      if (!trackRef.current) return

      const firstChild = trackRef.current?.firstChild as HTMLElement
      const offsetWidth = firstChild?.offsetWidth

      firstChildWidthRef.current = offsetWidth

      if (!resizeRef.current) {
        resizeRef.current = new ResizeObserver((entries) => {
          firstChildWidthRef.current = (
            entries[0].target as HTMLElement
          ).offsetWidth
        })
      }

      return () => {
        resizeRef.current?.disconnect()
      }
    }, [hasIntersected])

    /**
     * this renders the carousel slides only when the carousel
     * is in view. this is to prevent rendering of the carousel
     * if the user never views it. once rendered, the observer
     * will unobserve the carousel since we no longer need to
     * keep checking
     */
    useEffect(() => {
      if (lazy) {
        if (!ref.current || childrenArr.length === 0) {
          return
        }

        const carousel = ref.current
        const observer = new IntersectionObserver(
          (entries) => {
            const entry = entries[0]
            if (entry.isIntersecting && !hasIntersected) {
              setHasIntersected(true)
              observer.unobserve(carousel)
            }
          },
          {
            threshold: 0,
            root: trackRef.current,
            rootMargin: '20% 0% 20% 0%',
          }
        )

        observer.observe(carousel)
        directionRef.current = null

        return () => {
          observer.disconnect()
        }
      }
    }, [childrenArr.length, hasIntersected, lazy])

    /**
     * Handle navigation clicks to move the carousel in the desired direction.
     */
    const clickDirectionFactory = useCallback(
      (direction: DIRECTION) =>
        async function handleNavigationClick() {
          if (!trackRef.current || !firstChildWidthRef.current) return
          const offsetWidth = firstChildWidthRef.current
          directionRef.current = direction

          await yieldOrContinue('smooth')
          trackRef.current?.scrollBy({
            left: direction === DIRECTION.PREV ? -offsetWidth : offsetWidth,
            behavior: 'smooth',
          })
        },
      []
    )

    /**
     * this is to handle the case where the index is not 0 and needs to be
     * scrolled to the correct position on mount
     */
    useEffect(() => {
      if (!trackRef?.current || slideIndex === 0 || !firstChildWidthRef.current)
        return

      trackRef.current.scrollBy({
        left: firstChildWidthRef.current * slideIndex,
        // https://github.com/Microsoft/TypeScript/issues/28755
        behavior: 'instant' as ScrollBehavior,
      })
    }, [slideIndex, hasIntersected, firstChildWidthRef])

    /**
     * only render the whole carousel if `hasIntersected` is true
     * othwerise, only load the first image
     */
    return (
      <div
        id={carouselId}
        className={clsx(baseStyles.carousel, 'carousel', className)}
        data-tid="carousel"
        ref={ref}
        aria-label={props['aria-label']}
      >
        {hasIntersected ? (
          <>
            {shouldDisplayControls && totalSlides > 1 && (
              <div className={baseStyles.controls}>
                <CarouselButton
                  ref={prevButtonRef}
                  className={styles?.prevBtn}
                  onClick={clickDirectionFactory(DIRECTION.PREV)}
                  aria-controls={`${carouselId}-track`}
                  aria-label="Previous Slide"
                  data-tid="slider-prev"
                >
                  <ChevronLeftIcon id={`${carouselId}-nav-previous`} />
                </CarouselButton>
                <CarouselButton
                  className={styles?.nextBtn}
                  ref={nextButtonRef}
                  onClick={clickDirectionFactory(DIRECTION.NEXT)}
                  aria-controls={`${carouselId}-track`}
                  aria-label="Next Slide"
                  data-tid="slider-next"
                >
                  <ChevronRightIcon id={`${carouselId}-nav-next`} />
                </CarouselButton>
              </div>
            )}
            <div
              id={`${carouselId}-track`}
              className={clsx(
                !disableScrollBars && baseStyles.disableScrollBars,
                baseStyles.track,
                'carousel__track',
                styles?.track
              )}
              data-tid="carousel-track"
              ref={trackRef}
            >
              {childrenArr}
            </div>
          </>
        ) : (
          childrenArr[slideIndex ?? 0]
        )}
      </div>
    )
  }
)

ScrollSnapCarousel.displayName = 'ScrollSnapCarousel'
